import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilsService } from '../utils.service';
import { RestService } from '../../communication/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { PixelsService } from '../pixels.service';
import { EndpointsService } from '../../communication/endpoints.service';
import { ResumableUploadService } from '../resumable-upload.service';
import { ScriptsFetcherType } from '../utils';
// import { ImageDrop } from 'quill-image-drop-module';
import { ImageDialog } from '../../product/product';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { CommonModule } from '@angular/common';
declare var Quill: any;

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  static SCRIPT_SRC = 'https://cdn.creators3d.com/hotlink-ok/script/quill.js';
  static STYLE_SRC = 'https://cdn.creators3d.com/hotlink-ok/style/quill.snow_2.css';
  @Input() theme: string;
  @Input() placeholder: string;
  @Input() modules: Object;
  @Input('init-value') initValue: any;
  @Input('init-value-at') initValueAt: string;
  @Input() disable: boolean;
  @Input('max-height') maxHeight: string;
  @Input('set-value') setValue: Subject<string>;
  @Input('show-html') showHtml: boolean;
  @Input('click-img') clickImg: boolean;
  @Input('drop-image') dropImage: boolean;
  @Input('upload-on-drop') uploadOnDrop: boolean;
  @Input('show-asset') showAsset: boolean;
  @Output() textChange: EventEmitter<string>;
  @Output('uploading') uploading: EventEmitter<boolean>;
  @ViewChild('editor') el: ElementRef;
  @ViewChild('code') txtArea: ElementRef;
  private quill: any;
  private _initVal: string;
  private _imagesUploadStock: Array<string>;
  private _imagesStockUploading: boolean;
  constructor(
    private utils: UtilsService,
    private rest: RestService,
    private dialog: MatDialog,
    private pixels: PixelsService,
    private endpoints: EndpointsService,
    private resumableUpload: ResumableUploadService,
  ) {
    this.textChange = new EventEmitter(false);
    this.uploading = new EventEmitter(false);
    this._imagesUploadStock = [];
    this._imagesStockUploading = false;
    //
  }

  async ngOnInit() {
    if (this.setValue) {
      this.setValue.subscribe((val: string) => {
        this.setContents(val);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges | any) {
    if (changes.disable) {
      if (!changes.disable.firstChange && changes.disable.currentValue != changes.disable.previousValue) {
        this.toggleDisabled(changes.disable.currentValue);
        // this.init();
      }
    }
  }

  setContents(str: string) {
    if (!this.quill) {
      this._initVal = str;
      return;
    }
    this.quill.setText('');
    if (str) {
      str = this.utils.fixImages(str);
      try {
        this.quill.setContents(JSON.parse(str));
      } catch (e) {
        this.quill.clipboard.dangerouslyPasteHTML(0, this.utils.stripScripts(str));
      }
    }
  }

  toggleDisabled(state: boolean) {
    if (state) {
      this.quill.disable();
      if (!this.quill.root.textContent.trim() && !this.quill.root.querySelector('img') && !this.quill.root.querySelector('iframe')) {
        this.quill.container.parentElement.style.display = 'none';
      }
      this.preventInternalLinks();
    }
    else {
      this.quill.enable();
      // if (!this.quill.root.textContent.trim() && !this.quill.root.querySelector('img') && !this.quill.root.querySelector('iframe')) {
      this.quill.container.parentElement.style.display = '';
      // }
    }
  }

  preventInternalLinks() {
    let allAs = this.quill.root.querySelectorAll('a') as Array<HTMLLinkElement>;
    allAs.forEach(a => {
      if (!a.getAttribute('init-text-editor')) {
        a.setAttribute('init-text-editor', 'true');
        if (a.href) {
          const hostname = new URL(a.href).hostname;
          if (hostname == window.location.host) {
            a.addEventListener('click', (e: MouseEvent) => {
              let href = (e.target as HTMLLinkElement).href.replace('https://', '').replace('http://', '').replace(window.location.host, '');
              this.utils.forceRedirectTo(href);
              e.preventDefault();
            }, false);
          }
        }
      }
    });
  }

  getModules(): any {
    if (this.modules)
      return this.modules;
    if (this.disable) {
      return {
        toolbar: [
        ]
      };
    }
    const commands = ['link', 'video']
    if (this.clickImg)
      commands.unshift('image');
    if (this.showAsset)
      commands.unshift('asset');
    return {
      toolbar: [
        [{ header: [2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        // ['image', 'code-block']
        commands
      ],
      imageDrop: this.dropImage
    };
  }

  getTheme() {
    if (this.theme)
      return this.theme;
    if (this.disable)
      return 'bubble';
    return 'snow';
  }

  getPlaceholder() {
    return this.placeholder || 'notes';
  }

  async init() {
    const isFirst = typeof Quill === 'undefined';
    if (isFirst) {
      await this.utils.fetchCode(TextEditorComponent.SCRIPT_SRC, ScriptsFetcherType.SCRIPT);
      await this.utils.fetchCode(TextEditorComponent.STYLE_SRC, ScriptsFetcherType.STYLE);
    }
    let modules: any = this.getModules();
    let theme = this.getTheme();
    let placeholder = this.getPlaceholder();

    // if (this.modules)
    //   modules = this.modules;
    // else if (this.disable) {
    //   modules = {
    //     toolbar: [
    //     ]
    //   };
    //   theme = 'bubble';
    // }
    // if (this.theme)
    //   theme = this.theme;
    // if (typeof this.placeholder === 'string')
    //   placeholder = this.placeholder;

    if (this.showHtml)
      modules.toolbar.push(['showHtml']);

    // if (this.quill && this.el.nativeElement.parentElement) {
    //   delete this.quill;
    //   this.el.nativeElement.parentElement.innerHTML = '';
    // }
    if (!Quill.imports['modules/imageDrop']) {
      Quill.register('modules/imageDrop', ImageDrop);
    }
    this.quill = new Quill(this.el.nativeElement
      , {
        modules: modules,
        placeholder: placeholder,
        theme: theme
        // onKeyUp: () => {
        //   debugger;
        // }
      }
    );

    const toolbar = this.quill.getModule('toolbar');
    if (this.showAsset) {
      toolbar.addHandler('asset', () => {
        // let url = prompt('Enter 3D URL:');
        // const range = this.quill.getSelection();
        // this.utils.getU
        // if (url != null) {
        //   this.quill.insertEmbed(range, 'video', url);
        // }
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.obj,.glb,.fbx');
        // Listen upload local image and save to server
        input.value = null;
        input.onchange = async () => {
          const file = input.files[0];
          const bucket = this.endpoints.getEndpointDomain('cdn').replace('https://', '');
          let url = await this.resumableUpload.file(file, bucket);
          url = url.replace(bucket, this.endpoints.getEndpointDomain('cdn').replace('https://', ''));
          this.insertViewerToEditor(url);
        };
        input.click();
      });
      setTimeout(() => {
        if (this.quill.root.parentElement.parentElement.querySelector('.ql-asset'))
          toolbar.attach(this.quill.root.parentElement.parentElement.querySelector('.ql-asset'));
      });
    }

    toolbar.addHandler('video', () => {
      let type = 1; // 1 == video, 2 == iframe
      const getVideoUrl = (url: string) => {
        if (!url) return null;
        let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
          url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
          url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/) ||
          url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
        if (!match) return url;
        type = 2;
        if (match && match[2].length === 11) {
          return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
        if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
          return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
        }
        return null;
      }
      let url = prompt('Enter video URL or a link to YouTube or Vimeo:');
      // url = getVideoUrl(url)?.replace('://cdn.hexa3d.io/', '://img-cdn.azureedge.net/');
      url = getVideoUrl(url)?.replace('://cdn.hexa3d.io/', '://himg-cdn.com/');
      const range = this.quill.getSelection();
      if (url != null) {
        if (type === 1) {
          this.quill.insertEmbed(range, 'video', url);
          this.quill.pasteHTML(this.txtArea.nativeElement.value + `<br><a href="${url}?Content-Disposition=attachment" download>Download</a><br><br>`);
        }
        else
          this.quill.pasteHTML(this.txtArea.nativeElement.value + `
            <div class="iframe-wrap">
              <iframe src="${url}" frameborder="0"></iframe>
            </div><br>`);
        this.quill.insertEmbed(range, 'iframe', url);
      }
    });
    toolbar.addHandler('image', (image: string, callback: Function) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      // Listen upload local image and save to server
      input.value = null;
      input.onchange = () => {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          this.uploadImage(file);
        } else {
          console.warn('You could only upload images.');
        }
      };
      input.click();
    });

    if (this.initValue) {
      let str = this.initValueAt ? this.initValue[this.initValueAt] : this.initValue;
      this.setContents(str);
    }

    if (this.maxHeight) {
      this.el.nativeElement.style.maxHeight = this.maxHeight;
      this.el.nativeElement.style.overflow = 'auto';
    }
    this.el.nativeElement.classList.add('scrollbar');

    this.quill.on('text-change', (delta: any) => {
      // let contentObj = this.quill.getContents();
      // let content = JSON.stringify(contentObj);
      // if (Object.keys(contentObj).length == 1 && contentObj.ops.length == 1 && !contentObj.ops[0].insert.trim())
      //   content = '';
      // this.textChange.emit(content);

      let innerHTML = this.quill.root.innerHTML;
      // let content = this.quill.root.textContent;
      this.emitText();
      if (this.showHtml)
        this.txtArea.nativeElement.value = innerHTML;
      if (this.uploadOnDrop) {
        let imagesUpload = false;
        delta?.ops?.forEach((op: any) => {
          if (op.insert?.image?.indexOf('data:') === 0) {
            imagesUpload = true;
            this._imagesUploadStock.push(op.insert.image);
          }
        });
        if (imagesUpload)
          this.replaceBase64();
      }
    });

    if (this.disable) {
      this.toggleDisabled(true);
      // this.quill.disable();
      // if (!this.quill.root.textContent.trim() && !this.quill.root.querySelector('img') && !this.quill.root.querySelector('iframe')) {
      //   this.quill.container.parentElement.style.display = 'none';
      // }
    }

    if (this.showHtml) {
      const showHtmlButton = this.el.nativeElement.parentElement.querySelector('.ql-showHtml');
      showHtmlButton.addEventListener('click', () => {
        if (this.txtArea.nativeElement.style.display === 'block') {
          this.quill.pasteHTML(this.txtArea.nativeElement.value);
        }
        this.txtArea.nativeElement.style.display = this.txtArea.nativeElement.style.display === 'none' ? 'block' : 'none'
      });
    }

    this.el.nativeElement.style.display = 'block';
    if (this.clickImg) {
      this.el.nativeElement.addEventListener('click', (e: MouseEvent) => {
        const img = e.target as HTMLImageElement;
        if (img && img.tagName.toLowerCase() == 'img' && img.getAttribute('src')) {
          this.onImageClick(img);
        }
      }, false);
      // setTimeout(() => {
      //   let images = this.el.nativeElement.querySelectorAll('img');
      //   for (let i = 0; i < images.length; i++) {
      //     console.log(images[i]);
      //     images[i].addEventListener('click', (e: MouseEvent) => {
      //       const img = e.target as HTMLImageElement;
      //       if (img && img.getAttribute('src')) {
      //         this.onImageClick(img);
      //         img.classList.add('box-content');
      //       }
      //     }, false);
      //   }
      // });
    }
  }

  emitText() {
    if (this.isQuillEmpty())
      this.textChange.emit(null);
    else
      this.textChange.emit(this.utils.fixImages(this.quill.root.innerHTML));
  }

  async replaceBase64() {
    if (this._imagesUploadStock.length && !this._imagesStockUploading) {
      this._imagesStockUploading = true;
      this.uploading.emit(this._imagesStockUploading);
      let base64 = this._imagesUploadStock.splice(0, 1)[0];
      const mime = this.resumableUpload.base64MimeType(base64);
      const url = await this.resumableUpload.base64ToURL(base64, `image.${mime.replace('image/', '')}`, mime);
      this.quill.pasteHTML(this.txtArea.nativeElement.value.replace(` src="${base64}"`, ` src="${url}"`));
      this._imagesStockUploading = false;
      if (this._imagesUploadStock.length)
        this.replaceBase64();
      else
        this.uploading.emit(this._imagesStockUploading);
      this.emitText();
    }
  }

  async ngAfterViewInit() {
    await this.init();

    if (this._initVal) {
      this.setContents(this._initVal);
      delete this._initVal;
    }
  }

  onImageClick(img: HTMLImageElement) {
    const m = {
      title: 'Image Preview',
      url: img.src
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      data: m
    });
  }

  isQuillEmpty() {
    if (JSON.stringify(this.quill.getContents()) === '\{\"ops\":[\{\"insert\":\"\\n\"\}]\}') {
      return true;
    } else {
      return false;
    }
  }

  insertViewerToEditor(url: string) {
    url = url.replace('://cdn.hexa3d.io/', '://himg-cdn.com/');
    const src = `${this.endpoints.getEndpointDomain('threejsviewer')}/index.html?load=${url}&autorotate=auto&tv=${THREE_LATEST_VERSION}&auto-adjust=1`;
    const html = `
      <div class="iframe-wrap">
        <iframe src="${src}" frameborder="0"></iframe>
      </div><br><a href="${url}" download>Download</a><br><br>`;
    this.quill.pasteHTML(this.txtArea.nativeElement.value + html);
  }

  insertImageURLToEditor(url: string) {
    // push image url to rich editor.
    const range = this.quill.getSelection();
    try {
      if (range)
        this.quill.insertEmbed(range.index, 'image', url);
      else
        this.quill.insertEmbed(this.quill.getLength(), 'image', url);
    } catch (e) {
      this.quill.insertEmbed(0, 'image', url);
    }
  }

  insertImageFileToEditor(file: File) {
    const reader = new FileReader();
    reader.addEventListener('load', (arg: any) => {
      this.insertImageURLToEditor(arg.target.result);
    }, false);
    reader.readAsDataURL(file);
  }

  async uploadImage(file: File) {
    this.insertImageURLToEditor(await this.resumableUpload.file(file));
  }

  attach(event: any) {
    if (event.clipboardData && event.clipboardData.files && event.clipboardData.files[0]) {
      event.preventDefault();
      this.uploadImage(event.clipboardData.files[0]);
    }
  }

  ngOnDestroy() {
    if (this.setValue)
      this.setValue.unsubscribe();
  }
}


class ImageDrop {

  /**
   * Instantiate the module given a quill instance and any options
   * @param {Quill} quill
   * @param {Object} options
   */
  quill: any;
  constructor(quill: any, options = {}) {
    // save the quill reference
    this.quill = quill;
    // bind handlers to this instance
    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    // listen for drop and paste events
    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  /**
   * Handler for drop event to read dropped files from evt.dataTransfer
   * @param {Event} evt
   */
  handleDrop(evt: any) {
    evt.preventDefault();
    if (evt.dataTransfer && evt.dataTransfer.files && evt.dataTransfer.files.length) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
        }
      }
      this.readFiles(evt.dataTransfer.files, this.insert.bind(this));
    }
  }

  /**
   * Handler for paste event to read pasted files from evt.clipboardData
   * @param {Event} evt
   */
  handlePaste(evt: any) {
    if (evt.clipboardData && evt.clipboardData.items && evt.clipboardData.items.length) {
      this.readFiles(evt.clipboardData.items, (dataUrl: any) => {
        const selection = this.quill.getSelection();
        if (selection) {
          // we must be in a browser that supports pasting (like Firefox)
          // so it has already been placed into the editor
        }
        else {
          // otherwise we wait until after the paste when this.quill.getSelection()
          // will return a valid index
          setTimeout(() => this.insert(dataUrl), 0);
        }
      });
    }
  }

  /**
   * Insert the image into the document at the current cursor position
   * @param {String} dataUrl  The base64-encoded image URI
   */
  insert(dataUrl: string) {
    const index = (this.quill.getSelection() || {}).index || this.quill.getLength();
    this.quill.insertEmbed(index, 'image', dataUrl, 'user');
  }

  /**
   * Extract image URIs a list of files from evt.dataTransfer or evt.clipboardData
   * @param {File[]} files  One or more File objects
   * @param {Function} callback  A function to send each data URI to
   */
  readFiles(files: Array<any>, callback: Function) {
    // check each file for an image
    [].forEach.call(files, (file: any) => {
      if (!file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)) {
        // file is not an image
        // Note that some file formats such as psd start with image/* but are not readable
        return;
      }
      // set up file reader
      const reader = new FileReader();
      reader.onload = (evt) => {
        callback(evt.target.result);
      };
      // read the clipboard item or file
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (blob instanceof Blob) {
        reader.readAsDataURL(blob);
      }
    });
  }

}
