import { Injectable } from '@angular/core';
import { StorageService } from 'ng-storage-service';
import { RestService } from '../communication/rest.service';
import { EndpointsService } from '../communication/endpoints.service';
import { UtilsService } from './utils.service';
import { BodyService } from './body.service';
import { Subject } from 'rxjs';
import { ResumableCDN, ResumableState, ResumableSubject } from './enums';
import * as tus from 'tus-js-client'

@Injectable({
  providedIn: 'root'
})
export class ResumableUploadService { 
  constructor(
    private storage: StorageService,
    private rest: RestService,
    private endpoints: EndpointsService,
    private utils: UtilsService,
    private body: BodyService
  ) { }

  public sourceFiles(file: Blob): Subject<ResumableSubject> {
    return this.executeRequest(this.endpoints.getEndpointDomain('upload') + '/tus/upload/', file);
  }

  public clearCache() {
    if (this.storage.isSupported()) {
      for (let i in localStorage) {
        if (i.indexOf('tus-') > -1)
          this.storage.remove(i);
      }
    }
  }

  private executeRequest(endpoint: string, file: Blob): Subject<ResumableSubject> {
    const sub = new Subject<ResumableSubject>();
    const chunkSize = 1024 * 1024;
    if (this.storage.has('token'))
      endpoint += endpoint.indexOf('?') > -1 ? `&token=${this.storage.get('token')}` : `?token=${this.storage.get('token')}`;
 
      const r = new tus.Upload(file,
        {
          endpoint,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          chunkSize,
          metadata: {
            filename: (file as any)['name'] || 'blob',
            filetype: file.type
          },
          onError: (error: any) => {
            console.log("Failed because: " + error);
            sub.next({
              state: ResumableState.ERROR
            });
          },
          onProgress: (bytesUploaded: number, bytesTotal: number) => {
            let progress = Math.max((bytesUploaded / bytesTotal * 100), 0.01);
            sub.next({
              state: ResumableState.FILE_PROGRESS,
              object: {
                progress
              }
            });
          },
          onSuccess: () => {
            sub.next({
              state: ResumableState.COMPLETE,
              object: {
                message: r.url
              }
            });
          }
        });
      r.start();
   
    return sub;
  }
  private onRequestEnd() {
    this.body.resetNumOfRequestsInProgress();
  }

  private onRequestStart() {
    this.body.increaseNumOfRequestsInProgress();
  }
  public async file(file: File, bucket = this.endpoints.getEndpointDomain('cdn').replace('https://', '')): Promise<string> {
    this.onRequestStart();
    return new Promise((resolve: any, reject: any) => {
      this.executeRequest(this.endpoints.getEndpointDomain('upload') + '/tus/upload/', file).subscribe(async (res: ResumableSubject) => {
        switch (res.state) {
          case ResumableState.ERROR: {
            console.warn(res);
            this.onRequestEnd();
            reject(res);
            break;
          }
          case ResumableState.COMPLETE: {
            const payload = new ResumableCDN();
            payload.uploaded_file_name = `${file.name}`;
            payload.uploaded_file_url = res.object.message;
            payload.bucket = bucket;
            const t = await this.utils.observableToPromise(this.rest.afterCdn('post', payload));
            this.onRequestEnd();
            resolve(t.url);
            break;
          }
        }
      });
    });
  }

  public async base64ToURL(base64: string, fileName: string, mimeType: string, bucket = this.endpoints.getEndpointDomain('cdn').replace('https://', '')): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      fetch(base64)
      .then(res => res.blob())
      .then(async blob => {
        const file = new File([blob], fileName, { type: mimeType });
        try {
          resolve(await this.file(file, bucket));
        } catch (e) { reject(e); }
      })
    });
  }

  base64MimeType(encoded: string) {
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
  }
}
